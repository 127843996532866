<template>
  <div class="new-clients-container">
    <admin-header-vue alias="" pageName="New Role" />
    <form @submit.prevent="handleSubmit">
      <div class="new-client">
        <div class="columns">
          <div class="column is-4">
            <input-vue
              :required="true"
              :value="role.roleName"
              :min="1"
              type="text"
              :max="150"
              :submitted="submitted"
              @onChangeName="onChangeName"
              label="Role Name" />
          </div>
          <div class="column is-4">
            <div class="form-group">
              <p class="display-flex">
                Can Manage
              </p>
              <dropdown-wrap-vue @onClickOutside="onClickOutside">
                <div class="relative">
                  <b-input
                    type="search"
                    ref="products"
                    @focus="onFocus"
                    :icon-right="openProduct ? 'menu-up' : 'menu-down'"
                    v-model="searchRole"
                    class="form-control" />
                  <common-selection-vue
                    :searchText="searchRole"
                    name="role"
                    @focus="onFocus"
                    :open="openProduct"
                    :selected="role.roleList"
                    @onChange="onChangeRole"
                    :list="roles" />
                </div>
                <closable-tags-vue
                  @onChange="onRemoveRole"
                  name="role"
                  :selected="role.roleList" />
              </dropdown-wrap-vue>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column is-2">
            <div class="admin-wrap"><p>is Super-Admin? </p><input type="checkbox" v-model="role.isSuperAdmin" /></div>
          </div>
        </div>
          <div class="columns">
          <div class="column is-12">
        <role-permission-container-vue
            :topicList="role.permisssionList"
            :categoryCustomTopics="features"
            :submitted="submitted"
            @onRemoveTopics="onRemove"
            @addNewTopic="addNewParentTopic"
            @onUpdateTopic="onUpdateParentTopic"
            @onDelete="onDelete" />
          </div>
                </div>
        <div class="columns">
          <div class="column">
            <div class="submit-button">
              <b-button @click.prevent="handleSubmit">Add</b-button>
              <b-button @click.prevent="handleCancel">Cancel</b-button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import DropdownWrapVue from '../../../../../components/Common/DropdownWrap.vue'
import ToggleButtonVue from '../../../../../components/Common/ToggleButton.vue'
import CommonSelectionVue from '../../../../../components/Dropdowns/CommonSelection.vue'
import AdminHeaderVue from '../../../components/AdminHeader.vue'
import ClosableTagsVue from '../../../components/ClosableTags.vue'
import InputVue from '../../../components/Input.vue'
import RolePermissionContainerVue from './RolePermission/RolePermissionContainer.vue'

export default {
  name: 'AddRole',
  components: {
    AdminHeaderVue,
    InputVue,
    RolePermissionContainerVue,
    DropdownWrapVue,
    CommonSelectionVue,
    ClosableTagsVue,
    ToggleButtonVue
  },
  data () {
    return {
      role: {
        roleName: null,
        permisssionList: [],
        roleList: [],
        isSuperAdmin: false
      },
      openProduct: false,
      submitted: false,
      isValidName: false,
      searchRole: null

    }
  },
  computed: {
    ...mapState('feature', ['features']),
    ...mapState('role', ['roles'])
  },
  watch: {
    features () {
      this.autoFillPermission()
    },
    'role.isSuperAdmin' () {
      if (this.role.isSuperAdmin) {
        for (const permission of this.role.permisssionList) {
          permission.permissions = ['read', 'write', 'update', 'delete']
        }
      } else {
        for (const permission of this.role.permisssionList) {
          permission.permissions = []
        }
      }
    }
  },
  mounted () {
    this.getFeatures()
    this.getRoles()
  },
  methods: {
    ...mapActions('role', ['addRole', 'getRoles']),
    ...mapActions('feature', ['getFeatures']),
    autoFillPermission () {
      const permissions = []
      const index = 0
      for (const feature of this.features) {
        permissions.push({
          feature,
          index,
          permissions: [],
          isNew: true
        })
      }
      this.role.permisssionList = permissions
    },
    /** This method defines the behavior for when the industry name (Input value) is changed
     * @param name {String} - The name of the new industry
     * @param isValid {Boolean}
     * @public
     */
    onChangeName (name, isValid) {
      this.role.roleName = name
      this.isValidName = isValid
    },
    /** This method closes the dropdown that controls what roles are selectable
     * @public
     */
    onClickOutside () {
      this.openProduct = false
    },
    /** This method opens the roles dropdown
     * @public
     */
    onFocus () {
      this.$refs.products.$el.firstChild.focus()
      this.openProduct = true
    },
    /** This method adds/removes roles from the list of selected roles
     * @public
     */
    onChangeRole (name, isValid) {
      this.role.roleList = name
    },
    /** This method removes a role from the list of selected roles when it is deselected from outside the dropdown (clicking on the X beside the role name)
     * @public
     */
    onRemoveRole (object) {
      this.role.roleList = this.role.roleList.filter(
        (id) => id.r_id !== object.r_id
      )
    },
    /** This method validates the form data when the form is submitted
     * @public
     */
    checkPermissionList () {
      if (this.role.permisssionList.length) {
        for (const obj of this.role.permisssionList) {
          if (obj.feature == null) {
            return false
          }
        }
      }
      return true
    },
    /** This method submits the form
     * @public
     */
    handleSubmit (e) {
      this.submitted = true
      if (this.checkPermissionList() && !(this.isValidName)) {
        this.addRole(this.role)
        this.submitted = false
      }
    },
    /** This method redirects the user back to the roles and permissions page
     * @public
     */
    handleCancel () {
      this.$router.push('/admin/roles-and-permissions')
    },
    addNewParentTopic (object) {
      this.role.permisssionList.push(object)
    },
    onUpdateParentTopic (object, index) {
      if (this.role.permisssionList[index]) {
        this.role.permisssionList.splice(index, 1, object)
      }
    },
    onRemove (object) {
      // const child = []
      // this.role.permisssionList = child
      // if (!this.role.permisssionList.length) {
      //   this.role.permisssionList = [{ features: {}, permissions: [], isNew: true }]
      // }
    },
    onDelete (object, id) {
      this.role.permisssionList = this.role.permisssionList.filter((data, index) => index !== id)
    }
  }
}
</script>

<style lang="scss" scoped>
.new-clients-container {
   margin-top: 20px;
    background: var(--primary);
    border-radius: 4px;
  .new-client {
    .columns {
      margin: 0px;
      .flex {
        display: flex;
        // align-items: center;
      }
      .column {
        .admin-wrap {
          margin: 0px;
          color: #7f8fa4;
          font-size: 12px;
          font-family: Quicksand;
          display: flex;
          align-items: center;
          p {
            padding-right: 5px;
          }
        }
        .display-flex {
            margin: 0px;
            display: flex;
            color: #7f8fa4;
            font-size: 12px;
            font-family: Quicksand;
            padding: 0px 20px 0px 0px;
            display: flex;
            line-height: 20px;
            .astrik {
              color: red;
            }
          }
          .relative {
            position: relative;

            .required-error {
              color: red;
              position: absolute;
              font-size: 12px;
            }
            .relative {
              position: relative;
            }
          }
        .upload {
          width: 200px;
          height: 60px;
          align-items: center;
          justify-content: center;
          border: 1px solid #dbdbdb;
          border-radius: 4px;
          img {
            width: 200px;
            height: 60px;
            object-fit: contain;
            padding: 5px;
          }
        }
        .submit-button {
          display: flex;
          justify-content: flex-start;
          padding-top: 20px;
          .button {
            height: 100%;
            padding: 5px 10px;
            background: #2780eb;
            color: white;
            border: none;
            margin-right: 10px;
          }
        }
      }
    }
  }
}
</style>
